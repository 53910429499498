var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      staticClass: "animated fadeIn",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "b-col",
        { attrs: { sm: "4", md: "4", lg: "4", xl: "4" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(1, "Name") } },
            [
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.company.name.$error,
                },
                attrs: { type: "text", autocomplete: "off" },
                on: {
                  keyup: function ($event) {
                    return _vm.handleInputChanged("name")
                  },
                },
                model: {
                  value: _vm.company.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.company, "name", $$v)
                  },
                  expression: "company.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { sm: "8", md: "8", lg: "8", xl: "8" } },
        [
          _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(2, "Address") } },
            [
              _c("v-address", {
                attrs: {
                  params: { title: _vm.FormMSG(3, "Address") },
                  "address-id": _vm.company.addressId,
                  "is-submitted": true,
                  error: _vm.$v.company.addressId.$error,
                },
                on: {
                  change: _vm.handleDynamicAddressChange,
                  delete: _vm.handleDynamicAddressDelete,
                  "address-maplink-change": _vm.handleAddressMapLinkChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }