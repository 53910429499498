<template>
	<b-tr class="animated fadeIn" v-if="flag">
		<b-td style="vertical-align: middle; width: 2%" v-if="flag.id > 0">
			<b-form-checkbox
				v-model="flag.disabled"
				:title="checkboxTitle"
				v-b-tooltip.hover.left
				size="lg"
				@change="handleCheckboxChanged($event, 'disabled')"
			/>
		</b-td>
		<b-td v-if="flag.id === 0"></b-td>
		<b-td style="vertical-align: middle; width: 5%">
			<b-form-input style="text-align: center" type="text" autocomplete="off" disabled v-model="flagRef" />
		</b-td>

		<b-td style="width: 20%">
			<div class="d-flex flex-row align-items-center">
				<div>
					<b-button size="sm" class="btn bg-transparent border-0" v-b-tooltip.hover.bottom.html="`${flag.name}`">
						<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
					</b-button>
				</div>
				<b-form-input
					type="text"
					:class="{
						'is-invalid': $v.flag.name.$error
					}"
					autocomplete="off"
					v-model="flag.name"
					@keyup="handleInputChanged('name')"
				/>
			</div>
		</b-td>
		<b-td v-if="!forAllowances" style="width: 10%">
			<b-form-input
				type="text"
				v-model="flag.short"
				autocomplete="off"
				:class="{
					'is-invalid': $v.flag.short.$error
				}"
				@keyup="handleInputChanged('short')"
			/>
		</b-td>
		<b-td>
			<b-form-input
				type="text"
				:class="{
					'is-invalid': $v.flag.amount.$error
				}"
				autocomplete="off"
				v-model="flag.amount"
				@change="handleInputChanged('amount')"
			/>
		</b-td>
		<b-td v-if="forAllowances">
			<b-form-select v-model="flag.allowanceRate" @input="handleInputChanged('allowanceRate')" :options="periodicalOptions" />
		</b-td>
		<b-td style="width: 20%">
			<treeselect
				v-model="flag.category"
				:multiple="false"
				:clearable="false"
				@input="handleInputChanged('category')"
				:options="categories"
				:disable-branch-nodes="true"
				:placeholder="FormMSG(569, 'Select a category')"
				:class="{
					'is-invalid': $v.flag.category.$error
				}"
			>
				<div slot="option-label" slot-scope="{ node }">
					<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
				</div>
			</treeselect>
		</b-td>
		<b-td style="width: 18%">
			<payroll-code
				style-type="select"
				:value="flag.payCode"
				:class="{
					'is-invalid': $v.flag.payCode.$error
				}"
				@payroll-code:input="handlePayCodeChange"
				@payroll-code:reset="handleResetPayCode"
			/>
		</b-td>
		<b-td style="width: 20%">
			<treeselect
				v-model="flag.groupingCategory"
				:multiple="false"
				:clearable="false"
				@input="handleInputChanged('groupingCategory')"
				:options="mapGroup"
				:disable-branch-nodes="true"
				:placeholder="FormMSG(2, 'Select grouping category')"
				:class="{
					'is-invalid': $v.flag.groupingCategory.$error
				}"
			>
				<div slot="option-label" slot-scope="{ node }">
					<div class="treeselect-label p-2" :title="node.label">{{ node.label }}</div>
				</div>
			</treeselect>
		</b-td>
		<b-td v-if="!forAllowances">
			<input v-model="flag.color" type="color" @input="handleInputChanged('color')" />
		</b-td>
		<b-td v-if="!forAllowances">
			<i class="pi pi-star-fill cursor-pointer" v-if="flag.bookmark" @click="handleInputChanged('bookmark')" style="font-size: 25px; color: #edbd05"></i>
			<i class="pi pi-star cursor-pointer" v-else @click="handleInputChanged('bookmark')" style="font-size: 25px"></i>
		</b-td>
		<b-td style="vertical-align: middle; width: 2%">
			<component :is="getLucideIcon('MinusCircle')" color="#EA4E2C" class="cursor-pointer mr-1" :size="18" :stroke-width="3" @click="removeItem" />
		</b-td>
	</b-tr>
</template>
<script>
import PayrollCode from '@/components/Spaiectacle/PayrollCode';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import ColorPicker from 'primevue/colorpicker';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import _ from 'lodash';
import { removeAttributeTree } from '@/shared/utils';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
	name: 'FinanceTableCustomItemForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: { ColorPicker, Treeselect, PayrollCode },
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					let _flag = {
						...value,
						color: value.color.toString().includes('#') ? value.color : `#${value.color}`
					};
					this.flag = _.cloneDeep(_flag);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	props: {
		forAllowances: {
			type: Boolean,
			required: false,
			default: false
		},
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		existDataAlreadySubmitted: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		flagRef() {
			let newVal = this.forAllowances ? 6 + this.index : 5 + this.index;
			if ((this.flag.value = newVal)) {
				return newVal;
			} else {
				this.flag['value'] = newVal;
				this.handleInputChanged('value');
				return newVal;
			}
		},
		checkboxTitle() {
			return this.flag.disabled === false ? this.FormMSG(1, 'Disable') : this.FormMSG(2, 'Enable');
		},
		periodicalOptions() {
			return this.FormMenu(1333);
		},
		mapGroup() {
			let data = [...this.FormMenu(11031)].map((item) => ({ id: +item.value, label: item.text })).filter((item) => +item.id !== 3);
			return data;
		}
	},
	data() {
		return {
			flag: null,
			categories: []
		};
	},
	created() {
		this.getCategories();
	},
	methods: {
		handleBookmarkChange(bool) {
			this.flag.bookmark = !bool;
		},
		removeItem() {
			this.$emit('finance-table-flag-item-form:removed', {
				index: this.index,
				id: +this.flag.id
			});
		},
		async getCategories() {
			this.categories = [];
			await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false).then((result) => {
				this.categories = removeAttributeTree(result, 'children', null);
			});
			this.categories = [...this.categories, { id: 0, label: this.FormMSG(121212, 'Select a category') }].filter(
				(item) => item.label !== 'UNDEFINED -UNDEFINED '
			);
		},
		handleResetPayCode() {
			this.flag.payCode = null;
			this.handleInputChanged('payCode');
		},
		handlePayCodeChange(payload) {
			this.flag['payCode'] = payload;
			this.handleInputChanged('payCode');
		},
		handleInputChanged(fieldName) {
			if (fieldName == 'bookmark') {
				this.flag[fieldName] = !this.flag[fieldName];
			} else if (fieldName == 'amount' || fieldName == 'payCode') {
				this.flag[fieldName] = parseFloat(this.flag[fieldName]);
			} else {
				this.flag[fieldName] = this.flag[fieldName];
			}
			const _flag = {
				...this.flag,
				color: this.flag.color.substr(1)
			};
			this.$emit('finance-table-flag-item-form:updated', {
				index: this.index,
				item: _flag,
				isLineValid: !this.$v.$invalid
			});
		},
		handleCheckboxChanged(payload, fieldName) {
			this.flag[fieldName] = payload;

			this.$emit('finance-table-flag-item-form:updated', {
				index: this.index,
				item: this.flag
			});
		}
	},
	validations() {
		return {
			flag: {
				name: {
					required,
					min: minLength(3)
				},
				short: {
					required
				},
				amount: {
					required
				},
				category: {
					required
				},
				payCode: {
					required
				},
				groupingCategory: {
					required
				}
			}
		};
	}
};
</script>
<style>
input[type='color'] {
	width: 32px;
	height: 32px;
	border: 0;
	border-radius: 50%;
	padding: 0;
	overflow: hidden;
	cursor: pointer;
}
input[type='color']::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type='color']::-webkit-color-swatch {
	border: none;
}
</style>
