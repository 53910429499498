<template>
	<transition name="slide">
		<div
			:class="{
				'container-layout': $screen.width > 576,
				'container-mobile': $screen.width <= 576
			}"
			class="px-0"
		>
			<b-form @submit.prevent="submitForm">
				<b-row>
					<b-col cols="12">
						<b-card no-body class="card-border-blue-light">
							<b-row class="position-sticky back-with-title d-flex align-items-center pb-1 pl-0">
								<b-col cols="6">
									<h2 style="font-size: 1.5rem">{{ FormMSG(1, 'Finance Configuration') }}</h2>
								</b-col>
								<b-col cols="6" class="d-flex justify-content-end">
									<b-button
										type="submit"
										size="md"
										variant="secondary"
										class="w-138-px d-flex justify-content-center align-items-center"
										block
										style="margin-top: -8px"
										:disabled="watingSubmitForm"
									>
										<div class="d-flex justify-content-center align-items-center">
											<b-spinner v-show="watingSubmitForm" small />
											<div class="pl-2" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
										</div>
									</b-button>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="12">
									<div class="card-collapsible px-0 mt-3">
										<div class="card-collapsible px-0 mt-3">
											<div
												class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
												@click="handleClickHeader('company-details')"
											>
												<div class="title w-90">
													<component :is="getLucideIcon('Building')" :color="ICONS.ALARM_PLUS.color" :size="22" />
													<div class="fz-1-35 ml-3">{{ FormMSG(8, 'Company Details') }}</div>
												</div>
												<div class="cursor-pointer d-flex justify-content-end w-10">
													<component
														v-if="!configAccordion['company-details'].isVisible"
														:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
														:color="ICONS.CHEVRON_DOWN.color"
														:size="22"
													/>
													<component
														v-if="configAccordion['company-details'].isVisible"
														:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
														:color="ICONS.CHEVRON_UP.color"
														:size="22"
													/>
												</div>
											</div>
										</div>
										<b-collapse :visible="configAccordion['company-details'].isVisible">
											<div class="content-card-collapsible inside">
												<b-overlay :show.sync="isBusyCompany" rounded="lg" opacity="0.75" spinner-variant="primary">
													<b-row v-if="suppliers.length > 0" class="mt-1">
														<b-col
															cols="12"
															class="d-flex flex-row ml-2"
															v-for="(item, i) in suppliers"
															:key="i"
															:class="`${i < suppliers.length - 1 ? 'mb-2' : ''}`"
														>
															<fieldset
																style="width: 97%"
																:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															>
																<b-row>
																	<b-col sm="5" md="5" lg="5" xl="5" class="pt-3 px-0">
																		<company-form
																			:item="item"
																			:index="i"
																			@finance-company-form:updated="onLineCompanyChanged"
																			class="p-0 m-0"
																		/>
																	</b-col>
																	<b-col
																		sm="7"
																		md="7"
																		lg="7"
																		xl="7"
																		style="border-left: 0.125rem solid rgba(226, 230, 235, 0.85)"
																		class="pt-3"
																	>
																		<div v-if="item.supplierBankAccounts.length > 0">
																			<b-row v-for="(bankItem, j) in item.supplierBankAccounts" :key="j">
																				<b-col class="p-0 m-0">
																					<bank-form
																						:item="bankItem"
																						:index="j"
																						:supplier="{
																							id: +item.id,
																							index: i
																						}"
																						@supplier-bank-form:removed="removeBank"
																						@finance-bank-form:updated="onLineBankChanged"
																						class="p-0 m-0"
																					/>
																				</b-col>
																			</b-row>
																		</div>
																		<b-row v-else>
																			<b-col class="text-center py-2 text-empty-data">
																				{{ FormMSG(11, 'No data found') }}
																			</b-col>
																		</b-row>
																		<b-row class="pb-3">
																			<b-col cols="12" class="d-flex justify-content-end">
																				<b-button
																					size="sm"
																					variant="outline-dark"
																					class="w-200-px d-flex justify-content-center align-items-center"
																					block
																					@click="addNewBank(i)"
																				>
																					<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
																					<div class="px-2" style="margin-bottom: -2px">
																						{{ FormMSG(18, 'Add new bank') }}
																					</div>
																				</b-button>
																			</b-col>
																		</b-row>
																	</b-col>
																</b-row>
															</fieldset>
															<div class="d-flex align-items-center ml-2" style="width: 3%">
																<b-button
																	variant="danger"
																	size="sm"
																	class="ml-2 btn bg-transparent border-0"
																	@click="removeSupplier(item, i)"
																>
																	<component
																		:is="getLucideIcon('MinusCircle')"
																		:color="ICONS.TRASH.color"
																		:stroke-width="3"
																		:size="20"
																	/>
																</b-button>
															</div>
														</b-col>
													</b-row>
													<b-row v-else>
														<b-col class="text-center py-2 text-empty-data">{{ FormMSG(11, 'No data found') }}</b-col>
													</b-row>
													<b-row class="py-3">
														<b-col cols="12" class="d-flex justify-content-end">
															<b-button
																size="md"
																variant="outline-primary"
																class="w-250-px d-flex justify-content-center align-items-center"
																block
																@click="addNewCompany"
															>
																<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
																<div class="px-2" style="margin-bottom: -2px">{{ FormMSG(17, 'Add new company') }}</div>
															</b-button>
														</b-col>
													</b-row>
												</b-overlay>
												<b-row>
													<b-col class="ml-3 px-2">
														<fieldset
															:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
															class="w-100 pt-4 pb-3 d-flex justify-content-center align-items-center"
														>
															<b-button
																type="submit"
																size="md"
																variant="secondary"
																class="w-200-px d-flex justify-content-center align-items-center"
																block
																:disabled="watingSubmitForm"
																style="margin-top: -8px"
															>
																<div class="d-flex justify-content-center align-items-center">
																	<b-spinner v-show="watingSubmitForm" small />
																	<div class="pl-2" style="margin-top: 1px">{{ FormMSG(2, 'Save') }}</div>
																</div>
															</b-button>
														</fieldset>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<div class="card-collapsible px-0 mt-3">
										<div
											class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
											@click="handleClickHeader('tax-codes')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('FileType2')" :color="ICONS.ALARM_PLUS.color" :size="22" />
												<div class="fz-1-35 ml-3">{{ FormMSG(4, 'Tax codes') }}</div>
											</div>
											<div class="cursor-pointer d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['tax-codes'].isVisible"
													:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
													:color="ICONS.CHEVRON_DOWN.color"
													:size="22"
												/>
												<component
													v-if="configAccordion['tax-codes'].isVisible"
													:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
													:color="ICONS.CHEVRON_UP.color"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['tax-codes'].isVisible">
											<div class="content-card-collapsible inside">
												<div class="sub-title-layout pb-3">
													{{ FormMSG(5, 'Categories') }}
												</div>
												<b-row v-if="taxes.length > 0">
													<b-col v-for="(tax, i) in taxes" :key="i" sm="2" class="mb-2">
														<div class="wrap-flag">
															<div class="flag" :class="`flag-${tax.flagId}`" style="font-size: 0.7rem" @click="updateTax(tax)">
																{{ tax.name }}
															</div>
															<div class="cursor-pointer has-btn-delete" @click="removeFlag(tax.id, i, 0)" v-if="tax.flagId > 0">
																<component :is="getLucideIcon('MinusCircle')" color="#EA4E2C" :size="18" />
															</div>
														</div>
													</b-col>
												</b-row>
												<div class="text-center pb-2 text-empty-data" v-else>{{ FormMSG(11, 'No data found') }}</div>
												<!-- Let hide this button temporarily  -->
												<!-- <b-row class="pb-2">
													<b-col cols="12" class="d-flex justify-content-end">
														<b-button
															size="sm"
															variant="outline-primary"
															class="w-200-px d-flex justify-content-center align-items-center"
															block
															@click="addNewTax"
														>
															<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
															<div class="px-2" style="margin-bottom: -2px">
																{{ FormMSG(7, 'Add new tax code') }}
															</div>
														</b-button>
													</b-col>
												</b-row> -->
											</div>
										</b-collapse>
									</div>
									<div class="card-collapsible px-0 mt-3">
										<div
											class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
											@click="handleClickHeader('flags')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('Flag')" :color="ICONS.ALARM_PLUS.color" :size="22" />
												<div class="fz-1-35 ml-3">{{ FormMSG(3, 'Custom Flags') }}</div>
											</div>
											<div class="cursor-pointer d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['flags'].isVisible"
													:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
													:color="ICONS.CHEVRON_DOWN.color"
													:size="22"
												/>
												<component
													v-if="configAccordion['flags'].isVisible"
													:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
													:color="ICONS.CHEVRON_UP.color"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['flags'].isVisible">
											<div class="content-card-collapsible inside">
												<div class="sub-title-layout pb-3">
													{{ FormMSG(5, 'Categories') }}
												</div>
												<b-row v-if="flags.length > 0">
													<b-col v-for="(flag, i) in flags" :key="i" sm="2" class="mb-2">
														<div class="wrap-flag">
															<div
																class="flag"
																:class="`flag-${flag.flagId}`"
																style="font-size: 0.7rem"
																@click="updateFlag(flag)"
															>
																{{ flag.name }}
															</div>
															<div class="cursor-pointer has-btn-delete" @click="removeFlag(flag.id, i, 1)">
																<component :is="getLucideIcon('MinusCircle')" color="#EA4E2C" :size="18" />
															</div>
														</div>
													</b-col>
												</b-row>
												<div class="text-center pb-2 text-empty-data" v-else>{{ FormMSG(11, 'No data found') }}</div>
												<b-row class="pb-2">
													<b-col cols="12" class="d-flex justify-content-end">
														<b-button
															size="sm"
															variant="outline-primary"
															class="w-200-px d-flex justify-content-center align-items-center"
															block
															@click="addNewFlag"
														>
															<component :is="getLucideIcon('PlusCircle')" :size="18" :stroke-width="2" />
															<div class="px-2" style="margin-bottom: -2px">
																{{ FormMSG(6, 'Add new category') }}
															</div>
														</b-button>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
									<div class="card-collapsible px-0 mt-3">
										<div
											class="header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer"
											@click="handleClickHeader('per-diem')"
										>
											<div class="title w-90">
												<component :is="getLucideIcon('Scroll')" :color="ICONS.ALARM_PLUS.color" :size="22" />
												<div class="fz-1-35 ml-3">{{ FormMSG(301, 'Custom Allowances') }}</div>
											</div>
											<div class="cursor-pointer d-flex justify-content-end w-10">
												<component
													v-if="!configAccordion['per-diem'].isVisible"
													:is="getLucideIcon(ICONS.CHEVRON_DOWN.name)"
													:color="ICONS.CHEVRON_DOWN.color"
													:size="22"
												/>
												<component
													v-if="configAccordion['per-diem'].isVisible"
													:is="getLucideIcon(ICONS.CHEVRON_UP.name)"
													:color="ICONS.CHEVRON_UP.color"
													:size="22"
												/>
											</div>
										</div>
										<b-collapse :visible="configAccordion['per-diem'].isVisible">
											<div class="content-card-collapsible inside">
												<div class="sub-title-layout pb-3">
													{{ FormMSG(5, 'Categories') }}
												</div>
												<b-row>
													<b-col v-for="(flag, i) in perDiems" :key="i" sm="2" class="mb-2">
														<div class="wrap-flag">
															<div
																class="flag"
																:class="`flag-${flag.flagId}`"
																style="font-size: 0.7rem"
																@click="updateCustoms(flag, false)"
															>
																{{ flag.name }}
															</div>
														</div>
													</b-col>
													<b-col v-for="(flag, i) in allowances" :key="i" sm="2" class="mb-2">
														<div class="wrap-flag">
															<div
																class="flag"
																:class="`flag-${flag.flagId}`"
																style="font-size: 0.7rem"
																@click="updateCustoms(flag, true)"
															>
																{{ flag.name }}
															</div>
														</div>
													</b-col>
												</b-row>
											</div>
										</b-collapse>
									</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>
			</b-form>
			<finance-per-diem-form
				v-model="isPerDiemFormOpen"
				:title="titlePerDiemFormModal"
				:action="actionPerDiemFormModal"
				:for-allowances="forAllowances"
				:item="customs"
				@finance-flag-form-modal:refreshed="onRefreshed(1)"
				@finance-per-diem-modal:closed="onPerDiemModalClosed"
				@finance-flag-form-modal:reloaded="onFlagFormModalReloaded"
			/>
			<flag-form-modal
				v-model="isFlagFormOpen"
				:title="titleFlagFormModal"
				:action="actionFlagFormModal"
				:is-data-exist="isInitialDataCustomFlagExist"
				:item="flag"
				@finance-flag-form-modal:refreshed="onRefreshed(1)"
				@finance-flag-form-modal:closed="onFlagFormModalClosed"
				@finance-flag-form-modal:reloaded="onFlagFormModalReloaded"
			/>
			<tax-form-modal
				v-model="isTaxFormOpen"
				:title="titleTaxFormModal"
				:action="actionTaxFormModal"
				:is-data-exist="isInitialDataTaxExist"
				:item="tax"
				@finance-tax-form-modal:refreshed="onRefreshed(0)"
				@finance-tax-form-modal:closed="onTaxFormModalClosed"
				@finance-tax-form-modal:reloaded="onTaxFormModalReloaded"
			/>
			<b-modal
				header-class="header-class-modal-doc-package"
				header-bg-variant="warning"
				:title="this.FormMSG(21, 'Warning')"
				class="modal-warning"
				v-model="warningMissingItemModal"
				@ok="warningMissingItemModal = false"
				ok-variant="warning"
				ok-only
			>
				<div style="font-size: 1rem">
					{{ FormMSG(22, 'Please fill the field(s) required (coloring red) or remove one or all of them.') }}
				</div>
			</b-modal>
		</div>
	</transition>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import FlagFormModal from '@/components/Finance/FlagFormModal';
import FinancePerDiemForm from '@/components/Finance/CustomPerDiemModal';
import TaxFormModal from '@/components/Finance/TaxFormModal';
import { ACTION, FLAG_TYPE, SUPPLIER } from '@/shared/constants';
import { getProjectFlags, deleteProjectFlag } from '@/cruds/flag.crud';
import _ from 'lodash';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import CompanyForm from '@/components/Finance/CompanyForm';
import BankForm from '@/components/Finance/BankForm';
import { getSuppliers, addSupplier, updateSupplier, deleteSupplier, delSupplierBankAccount, addUpdSupplierBankAccount } from '@/cruds/suppliers.crud';

export default {
	name: 'ProjectFinance',
	mixins: [languageMessages, globalMixin],
	components: {
		FlagFormModal,
		TaxFormModal,
		'v-address': MainAddressLocation,
		CompanyForm,
		BankForm,
		FinancePerDiemForm
	},
	data() {
		return {
			watingSubmitForm: false,
			hideSaveHeader: true,
			configAccordion: {
				'company-details': {
					isVisible: false
				},
				flags: {
					isVisible: true
				},
				'tax-codes': {
					isVisible: true
				},
				'per-diem': {
					isVisible: true
				},
				allowances: {
					isVisible: true
				}
			},
			isFlagFormOpen: false,
			titleFlagFormModal: '',
			actionFlagFormModal: '',
			isTaxFormOpen: false,
			titleTaxFormModal: '',
			actionTaxFormModal: '',
			titlePerDiemFormModal: '',
			isPerDiemFormOpen: false,
			flags: [],
			flag: {},
			taxes: [],
			perDiems: [],
			allowances: [],
			customs: {},
			tax: {},
			suppliers: [],
			isValidSuppliers: [],
			addUpdSuppliers: [],
			isValidSupplierBanks: [],
			addUpdSupplierBanks: [],
			warningMissingItemModal: false,
			isBusyCompany: false,
			forAllowances: false,
			actionPerDiemFormModal: '',
			isInitialDataCustomFlagExist: false,
			isInitialDataTaxExist: false
		};
	},
	async created() {
		await this.reloadData();
		await this.getSuppliers();
	},
	methods: {
		async submitForm(e) {
			e.preventDefault();

			if (!this.validCompanies() || !this.validCompanyBanks()) {
				this.warningMissingItemModal = true;

				return;
			}
			// console.log({ addUpdSuppliers: this.addUpdSuppliers, addUpdSupplierBanks: this.addUpdSupplierBanks });

			if (this.addUpdSuppliers.length > 0 || this.addUpdSupplierBanks.length > 0) {
				this.isBusyCompany = true;
				this.watingSubmitForm = true;
				await this.manageAction();
				await this.getSuppliers();
				this.isBusyCompany = false;
				this.watingSubmitForm = false;
				this.createToastForMobile(this.FormMSG(16, 'Success'), this.FormMSG(25, 'Company details saved successfully'), '', 'success');
			} else {
				this.createToastForMobile(this.FormMSG(23, 'Warning'), this.FormMSG(24, 'Sorry, nothing to save.'), '', 'warning');
			}
		},
		async manageAction() {
			for (const supplier of this.addUpdSuppliers) {
				if (+supplier.id === 0) {
					// add
					const newSupplier = await addSupplier(supplier);

					// update
					await updateSupplier({
						id: +newSupplier.id,
						supplier: _.omit(supplier, ['address']),
						address: {}
					});
					// if exist index supplier in "addUpdSupplierBanks" but id supplier 0
					const idxSupplier = this.addUpdSupplierBanks.findIndex((company) => company.index === supplier.index && +company.id === 0);
					if (idxSupplier !== 1 && idxSupplier >= 0) {
						this.addUpdSupplierBanks[idxSupplier].id = +newSupplier.id;
					}
				} else {
					await updateSupplier({
						id: +supplier.id,
						supplier: _.omit(supplier, ['address']),
						address: {}
					});
				}
			}

			// supplier banks
			for (const supplier of this.addUpdSupplierBanks) {
				const { id, banks } = supplier;
				for (const bank of banks) {
					await addUpdSupplierBankAccount(+bank.id, {
						supplierId: +id,
						...bank
					}).catch((error) => {
						console.error({ error });
					});
				}
			}
		},
		handleClickHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
			// console.log({data});
		},
		addNewFlag(e) {
			e.preventDefault();
			this.isFlagFormOpen = true;
			this.titleFlagFormModal = this.FormMSG(9, 'New Flag Category');
			this.actionFlagFormModal = ACTION.ADD;
		},
		addNewCustomPerDiem(e, forAllowances) {
			e.preventDefault();
			this.forAllowances = forAllowances;
			this.isPerDiemFormOpen = true;
			forAllowances
				? (this.titlePerDiemFormModal = this.FormMSG(910, 'New Allowances Category'))
				: (this.titlePerDiemFormModal = this.FormMSG(900, 'New PerDiem Category'));
			this.actionPerDiemFormModal = ACTION.ADD;
		},
		updateCustoms(item, forAllowances) {
			this.forAllowances = forAllowances;
			this.actionPerDiemFormModal = ACTION.UPDATE;
			this.customs = item;
			this.titlePerDiemFormModal = this.FormMSG(1400, 'Update') + ` ${item.name}`;
			this.isPerDiemFormOpen = true;
		},
		updateFlag(item) {
			this.flag = item;
			this.actionFlagFormModal = ACTION.UPDATE;
			this.isInitialDataCustomFlagExist = this.flag.projectFlagItem > 0;
			this.titleFlagFormModal = this.FormMSG(14, 'Update Flag') + `: "${item.name}"`;
			this.isFlagFormOpen = true;
		},
		addNewTax(e) {
			e.preventDefault();
			this.isTaxFormOpen = true;
			this.titleTaxFormModal = this.FormMSG(10, 'Tax Codes');
			this.actionTaxFormModal = ACTION.ADD;
		},
		updateTax(item) {
			this.tax = item;
			this.actionTaxFormModal = ACTION.UPDATE;
			this.isInitialDataTaxExist = this.tax.projectFlagItem > 0;
			this.titleTaxFormModal = this.FormMSG(15, 'Update Tax') + `: "${item.name}"`;
			this.isTaxFormOpen = true;
		},
		onPerDiemModalClosed() {
			this.isPerDiemFormOpen = false;
		},
		onFlagFormModalClosed() {
			this.isFlagFormOpen = false;
		},
		onTaxFormModalClosed() {
			this.isTaxFormOpen = false;
		},
		async reloadData() {
			await getProjectFlags().then((res) => {
				if (res) {
					this.flags = res.customFlags;
					this.taxes = res.taxeFlags;
					this.allowances = res.customAllowancesFlags;
					this.perDiems = res.customPerDiemFlags;
				}
			});
		},
		removeFlag(id, index, type) {
			const action = async () => {
				await deleteProjectFlag(id)
					.then((result) => {
						if (result) {
							this.createToastForMobile(this.FormMSG(16, 'Success'), this.FormMSG(12, 'Flag removed successfully'), '', 'success');
						}
					})
					.catch((error) => {
						console.error({ error });
					})
					.finally(() => {
						if (FLAG_TYPE.TAX === type) {
							this.taxes.splice(index, 1);
						} else if (FLAG_TYPE.DEFAULT_FLAG === type) {
							this.flags.splice(index, 1);
						}
					});
			};

			this.confirmModal(action, this.FormMSG(13, 'Are you sure to remove this flag and all of those children ?'), 'danger');
		},
		async onRefreshed() {
			await this.reloadData();
		},
		async onFlagFormModalReloaded(payload) {
			if (!_.isNil(payload)) {
				await this.onRefreshed(FLAG_TYPE.DEFAULT_FLAG);
			}
		},
		async onTaxFormModalReloaded(payload) {
			if (!_.isNil(payload)) {
				await this.onRefreshed(FLAG_TYPE.TAX);
			}
		},
		async getSuppliers() {
			await getSuppliers({
				name: null,
				type: SUPPLIER.LEGAL_ENTITY
			})
				.then((records) => {
					this.suppliers = records;
					this.suppliers.forEach((supplier, i) => {
						this.isValidSuppliers.push(true);
						this.isValidSupplierBanks.push({
							index: i,
							banks: []
						});
						supplier.supplierBankAccounts.forEach(() => {
							this.isValidSupplierBanks[i].banks.push(true);
						});
					});
				})
				.catch((errors) => {
					console.error({ errors });
				});
		},
		addNewCompany() {
			const supplier = {
				id: 0,
				name: '',
				addressId: 0,
				type: SUPPLIER.LEGAL_ENTITY,
				supplierBankAccounts: []
			};

			this.suppliers.push(supplier);
			this.isValidSuppliers.push(false);
			this.isValidSupplierBanks.push({
				index: this.suppliers.length - 1,
				banks: []
			});
		},
		addNewBank(supplierIndex) {
			const bank = {
				id: 0,
				bankName: '',
				iban: '',
				bic: '',
				currency: 0
			};

			const idx = this.addUpdSuppliers.findIndex((supplier) => supplier.index === supplierIndex && +supplier.id === 0);
			if (idx !== -1) {
				this.suppliers[supplierIndex] = Object.assign(this.suppliers[supplierIndex], this.addUpdSuppliers[idx]);
			}

			this.suppliers[supplierIndex].supplierBankAccounts.push(bank);
			this.isValidSupplierBanks[supplierIndex].banks.push(false);
		},
		removeSupplier(item, index) {
			const updateVariables = (index) => {
				this.suppliers.splice(index, 1);

				const idx = this.addUpdSuppliers.findIndex((supplier) => supplier.index === index);
				if (idx !== -1) {
					this.addUpdSuppliers.splice(idx, 1);
				}

				this.isValidSuppliers.splice(index, 1);
			};

			if (+item.id === 0) {
				updateVariables(index);
			} else {
				const action = async () => {
					await deleteSupplier(item)
						.then((result) => {
							if (result) {
								updateVariables(index);
							}
						})
						.catch((e) => {
							console.error({ e });
						});
				};
				this.confirmModal(action, this.FormMSG(19, 'Are you sure to remove this company ?'), 'danger');
			}
		},
		onLineCompanyChanged({ index, item, isLineValid }) {
			if (this.addUpdSuppliers.length === 0) {
				this.addUpdSuppliers.push({
					...item,
					index
				});
			} else {
				const idx = this.addUpdSuppliers.findIndex((flagItem) => flagItem.index === index);
				if (idx === -1) {
					this.addUpdSuppliers.push({
						...item,
						index
					});
				} else {
					this.addUpdSuppliers[idx] = Object.assign(this.addUpdSuppliers[idx], item);
				}
			}

			this.isValidSuppliers[index] = isLineValid;
		},
		removeBank({ id, index, supplier }) {
			// console.log({ removeBank: payload });
			const updateVariables = (index, supplier) => {
				this.suppliers[supplier.index].supplierBankAccounts.splice(index, 1);

				const idxSupplier = this.addUpdSupplierBanks.findIndex((company) => company.index === supplier.index);
				if (idxSupplier !== -1) {
					const idx = this.addUpdSupplierBanks[idxSupplier].banks.findIndex((bank) => bank.index === index);
					if (idx !== -1) {
						this.addUpdSupplierBanks[idxSupplier].banks.splice(idx, 1);
					}
				}

				this.isValidSupplierBanks[supplier.index].banks.splice(index, 1);
			};

			if (id === 0) {
				updateVariables(index, supplier);
			} else {
				const action = async () => {
					await delSupplierBankAccount(id)
						.then((result) => {
							if (result) {
								updateVariables(index, supplier);
							}
						})
						.catch((e) => {
							console.error({ e });
						});
				};
				this.confirmModal(action, this.FormMSG(20, 'Are you sure to remove this bank account ?'), 'danger');
			}
		},
		onLineBankChanged({ index, item, supplier, isLineValid }) {
			// console.log({ bankChanged: payload });
			if (this.addUpdSupplierBanks.length === 0) {
				this.addUpdSupplierBanks.push({
					index: supplier.index,
					id: supplier.id,
					banks: [
						{
							...item,
							index
						}
					]
				});
			} else {
				const idxSupplier = this.addUpdSupplierBanks.findIndex((company) => company.index === supplier.index);
				if (idxSupplier === -1) {
					this.addUpdSupplierBanks.push({
						index: supplier.index,
						id: supplier.id,
						banks: [
							{
								...item,
								index
							}
						]
					});
				} else {
					const idx = this.addUpdSupplierBanks[idxSupplier].banks.findIndex((bank) => bank.index === index);
					if (idx === -1) {
						this.addUpdSupplierBanks[idxSupplier].banks.push({
							...item,
							index
						});
					} else {
						this.addUpdSupplierBanks[idxSupplier].banks[idx] = Object.assign(this.addUpdSupplierBanks[idxSupplier].banks[idx], item);
					}
				}
			}

			this.isValidSupplierBanks[supplier.index].banks[index] = isLineValid;
		},
		validCompanies() {
			if (this.isValidSuppliers.length === 0) return true;
			const isValidSuppliers = this.isValidSuppliers.join(',');

			return !/false/.test(isValidSuppliers);
		},
		validCompanyBanks() {
			let valids = [];

			this.isValidSupplierBanks.forEach((company) => {
				company.banks.forEach((bank) => {
					valids.push(bank);
				});
			});

			const isValid = valids.join(',');

			return !/false/.test(isValid);
		}
	}
};
</script>
<style lang="scss" scoped>
.text-empty-data {
	font-size: 1.15rem;
	color: rgba(6, 38, 62, 0.64);
	letter-spacing: 0.05rem;
}
</style>
