<template>
	<div class="mt-2">
		<b-row class="px-1">
			<b-col :cols="4">
				<treeselect @change="handleChange" v-model="equipment.type" :options="equipmentOption"></treeselect>
			</b-col>
			<b-col :cols="5"> <b-form-input @change="handleChange" v-model="equipment.description" type="text"></b-form-input></b-col>
			<b-col :cols="2"> <b-form-input @change="handleChange" v-model="equipment.quantity" type="number"></b-form-input></b-col>
			<b-col style="align-items: center; display: flex"
				><div class="cursor-pointer" @click="handleDelete(equipment.id)">
					<MinusCircle color="tomato" :size="20" /></div
			></b-col>
		</b-row>
	</div>
</template>
<script>
import { MinusCircle } from 'lucide-vue';
import Treeselect from '@riophae/vue-treeselect';
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'premisesEquipment',
	components: { MinusCircle, Treeselect },
	mixins: [languageMessages, globalMixin],
	props: {
		equipment: {
			type: Object,
			required: true
		}
	},
	computed: {
		equipmentOption() {
			const menu = this.FormMenu(1401).map((menu) => {
				return {
					id: menu.value,
					label: menu.text
				};
			});
			return menu;
		}
	},
	methods: {
		handleDelete(id) {
			this.$emit('delete', id);
		},
		handleChange() {
			let payload = {
				...this.equipment,
				description: this.equipment.description,
				type: parseInt(this.equipment.type, 10),
				quantity: parseInt(this.equipment.quantity, 10)
			};
			this.$emit('change', payload);
		}
	}
};
</script>
<style lang=""></style>
