var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "animated fadeIn",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside my-0 py-0 pb-0"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(8, "General")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      {
                        class: `${_vm.$screen.width <= 576 ? "pb-2" : "py-2"}`,
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "9" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(2, "Category") } },
                              [
                                _c("b-form-input", {
                                  class: {
                                    "is-invalid":
                                      _vm.isSubmitted && _vm.$v.tax.name.$error,
                                  },
                                  attrs: { type: "text", autocomplete: "off" },
                                  model: {
                                    value: _vm.tax.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tax, "name", $$v)
                                    },
                                    expression: "tax.name",
                                  },
                                }),
                                _vm.isSubmitted &&
                                _vm.$v.tax.name.$error &&
                                _vm.$screen.width <= 576
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                3,
                                                "Please, category name is required"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { sm: "3" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.FormMSG(4, "Abbreviation"),
                                },
                              },
                              [
                                _c("b-form-input", {
                                  class: {
                                    "is-invalid":
                                      _vm.isSubmitted &&
                                      _vm.$v.tax.short.$error,
                                  },
                                  attrs: { type: "text", autocomplete: "off" },
                                  model: {
                                    value: _vm.tax.short,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tax, "short", $$v)
                                    },
                                    expression: "tax.short",
                                  },
                                }),
                                _vm.isSubmitted &&
                                _vm.$v.tax.short.$error &&
                                _vm.$screen.width <= 576
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                5,
                                                "Please, short name is required"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-2" },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside my-0 py-0 pb-0"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(6, "Information")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      {
                        class: `${_vm.$screen.width <= 576 ? "pb-2" : "py-2"}`,
                      },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: { label: _vm.FormMSG(7, "Description") },
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    type: "textarea",
                                    rows: "2",
                                    autocomplete: "off",
                                  },
                                  model: {
                                    value: _vm.tax.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tax, "description", $$v)
                                    },
                                    expression: "tax.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            {
              class: `${_vm.$screen.width <= 992 ? "mt-2 pb-4" : "mt-2 pb-2"}`,
            },
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside my-0 py-0 pb-0"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(1, "Codes")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          [
                            _vm.$screen.width < 992 &&
                            _vm.tax.projectFlagItem.length > 0
                              ? _c("card-list-builder", {
                                  class: `${
                                    _vm.tax.projectFlagItem.length > 0
                                      ? "pb-3"
                                      : ""
                                  }`,
                                  attrs: {
                                    "use-new-version": true,
                                    "toggle-mode": true,
                                    items: _vm.tax.projectFlagItem,
                                    fields: _vm.taxFields,
                                    validators: _vm.validatorPatterns,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "disabled",
                                        fn: function ({ item, index }) {
                                          return item.id > 0
                                            ? [
                                                _c("b-form-checkbox", {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.focus.right",
                                                      modifiers: {
                                                        focus: true,
                                                        right: true,
                                                      },
                                                    },
                                                  ],
                                                  attrs: {
                                                    title:
                                                      _vm.checkboxTitle(item),
                                                    size: "lg",
                                                    disabled:
                                                      _vm.patternForHidingRemoveBtnInTaxItem(
                                                        index
                                                      ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleCheckboxChanged(
                                                        $event,
                                                        "disabled",
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.disabled,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "disabled",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.disabled",
                                                  },
                                                }),
                                              ]
                                            : undefined
                                        },
                                      },
                                      {
                                        key: "factor",
                                        fn: function ({
                                          item,
                                          error,
                                          index,
                                          isLineValid,
                                        }) {
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "is-invalid": error,
                                              },
                                              attrs: {
                                                size: "sm",
                                                type: "number",
                                                autocomplete: "off",
                                                step: "0.01",
                                                placeholder: "0",
                                                min: "0",
                                              },
                                              on: {
                                                keyup: function ($event) {
                                                  return _vm.handleInputChanged(
                                                    item.factor,
                                                    "factor",
                                                    index,
                                                    isLineValid,
                                                    2
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.factor,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "factor", $$v)
                                                },
                                                expression: "item.factor",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                      {
                                        key: "short",
                                        fn: function ({
                                          item,
                                          error,
                                          index,
                                          isLineValid,
                                        }) {
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "is-invalid": error,
                                              },
                                              attrs: {
                                                size: "sm",
                                                type: "text",
                                                autocomplete: "off",
                                              },
                                              on: {
                                                keyup: function ($event) {
                                                  return _vm.handleInputChanged(
                                                    item.short,
                                                    "short",
                                                    index,
                                                    isLineValid
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.short,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "short", $$v)
                                                },
                                                expression: "item.short",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                      {
                                        key: "name",
                                        fn: function ({
                                          item,
                                          error,
                                          index,
                                          isLineValid,
                                        }) {
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "is-invalid": error,
                                              },
                                              attrs: {
                                                size: "sm",
                                                type: "text",
                                                autocomplete: "off",
                                              },
                                              on: {
                                                keyup: function ($event) {
                                                  return _vm.handleInputChanged(
                                                    item.name,
                                                    "name",
                                                    index,
                                                    isLineValid
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.name,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "name", $$v)
                                                },
                                                expression: "item.name",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                      {
                                        key: "category",
                                        fn: function ({
                                          item,
                                          error,
                                          index,
                                          isLineValid,
                                        }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                ref: _vm.generateTextId(
                                                  `tree-select-${index}`
                                                ),
                                                staticClass: "inline-tax-item",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.fixEventTreeselect(
                                                      item.category,
                                                      "category",
                                                      isLineValid,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-tree-select", {
                                                  class: {
                                                    "is-invalid": error,
                                                  },
                                                  attrs: {
                                                    multiple: false,
                                                    options: _vm.taxCostCenter,
                                                    "disable-branch-nodes": true,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.handleInputChanged(
                                                        item.category,
                                                        "category",
                                                        index,
                                                        isLineValid,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "option-label",
                                                        fn: function ({
                                                          node,
                                                        }) {
                                                          return _c("div", {}, [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    node.label,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    node.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: item.category,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "category",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.category",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "digitalSubmission",
                                        fn: function ({ item, index }) {
                                          return [
                                            _c("b-form-checkbox", {
                                              attrs: { size: "lg" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.handleCheckboxChanged(
                                                    $event,
                                                    "digitalSubmission",
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.digitalSubmission,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "digitalSubmission",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.digitalSubmission",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                      {
                                        key: "actions",
                                        fn: function (data) {
                                          return [
                                            !_vm.patternForHidingRemoveBtnInTaxItem(
                                              data.index
                                            )
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "ml-2 btn bg-transparent border-0",
                                                    attrs: {
                                                      variant: "danger",
                                                      size: "sm",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onLineTableRemoved(
                                                          {
                                                            index: data.index,
                                                            id: data.item.id,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.TRASH.name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.ICONS.TRASH
                                                              .color,
                                                          size: 20,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _vm.$screen.width < 992 &&
                            _vm.tax.projectFlagItem.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-center pb-3 mb-2",
                                    staticStyle: { "font-size": "1rem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            15,
                                            "Please, add a new tax"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$screen.width >= 992 &&
                            _vm.tax.projectFlagItem.length > 0
                              ? _c(
                                  "b-table-simple",
                                  { attrs: { small: "", borderless: true } },
                                  [
                                    _c(
                                      "b-thead",
                                      {
                                        staticStyle: {
                                          color: "rgba(6, 38, 62, 0.84)",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-tr",
                                          {
                                            staticStyle: {
                                              "line-height": "8px",
                                            },
                                          },
                                          [
                                            _vm.isDataExist &&
                                            _vm.tax.projectFlagItem.length > 0
                                              ? _c("b-th")
                                              : _vm._e(),
                                            _c("b-th", [_vm._v("%")]),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(10, "Ref."))
                                              ),
                                            ]),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(11, "Description")
                                                )
                                              ),
                                            ]),
                                            _c("b-th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    12,
                                                    "Control account"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("b-th"),
                                            _c("b-th"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-tbody",
                                      { staticClass: "text-center" },
                                      _vm._l(
                                        _vm.tax.projectFlagItem,
                                        function (item, i) {
                                          return _c("item-form", {
                                            key: i,
                                            ref: _vm.generateTextId(
                                              `item-form-${i}`
                                            ),
                                            refInFor: true,
                                            attrs: {
                                              index: i,
                                              item: item,
                                              "hide-remove-first-line":
                                                _vm.patternForHidingRemoveBtnInTaxItem(
                                                  i
                                                ),
                                              "cost-center": _vm.taxCostCenter,
                                              existDataAlreadySubmitted:
                                                _vm.existDataAlreadySubmitted,
                                            },
                                            on: {
                                              "finance-table-tax-item-form:updated":
                                                _vm.onLineTableChanged,
                                              "finance-table-tax-item-form:removed":
                                                _vm.onLineTableRemoved,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$screen.width >= 992 &&
                            _vm.tax.projectFlagItem.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "text-center pb-3",
                                    staticStyle: { "font-size": "1rem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            15,
                                            "Please, add a new tax"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-row", { staticClass: "mb-2 pb-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end",
                          staticStyle: {
                            width: "93.5%",
                            margin: "-10px 0 0 0",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "w-200-px d-flex justify-content-center align-items-center",
                              attrs: {
                                size: "sm",
                                variant: "outline-primary",
                                disabled: _vm.$v.$invalid,
                              },
                              on: { click: _vm.addNewTax },
                            },
                            [
                              _c(_vm.getLucideIcon("PlusCircle"), {
                                tag: "component",
                                attrs: { size: 16, "stroke-width": 2 },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "px-2",
                                  staticStyle: { "margin-bottom": "-2px" },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(13, "Add new tax code")
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center mt-2",
                  style: `display: ${
                    _vm.displayAddBtn ? "block" : "none"
                  } !important;`,
                  attrs: {
                    sm: "4",
                    md: "4",
                    lg: "4",
                    xl: "4",
                    "offset-sm": "5",
                    "offset-md": "5",
                    "offset-lg": "5",
                    "offset-xl": "5",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      ref: "submit",
                      staticClass: "w-138-px",
                      attrs: {
                        type: "submit",
                        size: "md",
                        variant: "outline-primary",
                        block: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(14, "Save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-warning",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            "header-bg-variant": "warning",
            title: this.FormMSG(17, "Warning"),
            "ok-variant": "warning",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.warningMissingFlagItemModal = false
            },
          },
          model: {
            value: _vm.warningMissingFlagItemModal,
            callback: function ($$v) {
              _vm.warningMissingFlagItemModal = $$v
            },
            expression: "warningMissingFlagItemModal",
          },
        },
        [
          _c("div", { staticStyle: { "font-size": "1rem" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    18,
                    "Please fill the field(s) required (coloring red) in codes section or remove one or all of them."
                  )
                ) +
                "\n\t\t"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }