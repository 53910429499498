<template>
	<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" class="px-0">
		<div :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
			<div class="title" v-if="$screen.width <= 576">
				<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
					{{ FormMSG(10, 'Settings') }}
				</h1>
			</div>
			<b-col cols="12" class="w-100 px-0">
				<b-tabs cards active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold" v-model="tabIndex">
					<b-tab :title="getTitle">
						<ProjectForm />
					</b-tab>
					<b-tab lazy :title="FormMSG(22, 'Departments and functions')">
						<department />
					</b-tab>
					<b-tab lazy :title="labelCategorie" v-if="useFinance">
						<NewCategory />
					</b-tab>
					<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useFinance" :title="getMemoDealTitle" @click="refreshData">
						<memo-deals :show-pay-code="showPayCode" />
					</b-tab>
					<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useLocations" :title="FormMSG(30, 'Premises')">
						<Premises />
					</b-tab>
					<b-tab lazy v-if="!isFilmSingle && !isFilmSingleFree && useFinance" :title="FormMSG(26, 'Finance')">
						<Finance />
					</b-tab>
					<b-tab v-if="!isFilmSingle && !isFilmSingleFree && useFimalac" lazy :title="FormMSG(27, 'Fimalac')">
						<Fimalac />
					</b-tab>
				</b-tabs>
			</b-col>
		</div>
	</div>
</template>

<script>
import NewCategory from './budget/NewCategoryScreen';
import MemoDeals from './projects/MemoDeals';
import ProjectForm from './projects/ProjectForm';
import Department from './department/department';
import languageMessages from '@/mixins/languageMessages';
import Fimalac from './projects/FimalacProject';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { addLicenseRequestForAndroidSubscription } from '@/cruds/licenseRequest.crud';
import Premises from './Premises/premises.vue';
import Finance from '@/components/Finance/Finance';
import TimeManagement from '../components/Finance/TimeManagement.vue';

export default {
	name: 'ProjectAdministration',
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],
	components: {
		MemoDeals,
		ProjectForm,
		Department,
		NewCategory,
		Premises,
		Finance,
		TimeManagement,
		Fimalac
	},
	data() {
		return {
			carbonModel: 0,
			tabIndex: 0,
			showModalConfirmationIOS: false,
			showPayCode: false
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.carbonModel = store.state.myProject.carbonModel;
		});
		this.$bus.$on('bus:projectevent', (payload) => {
			this.carbonModel = payload.carbonModel;
		});
	},
	computed: {
		useLocations() {
			return store.state.myProject.useLocations;
		},
		useFimalac() {
			let license = store.state.myProject.license !== undefined ? store.state.myProject.license.license : '';
			let isFimalacLicense = /fimalac/gi.test(license);
			return store.isAdmin() && isFimalacLicense;
		},
		useFinance() {
			return store.state.myProject.useFinance;
		},
		labelCategorie() {
			return this.FormMSG(21, 'Budget categories');
		},
		getMemoDealTitle() {
			if (store.isPme()) {
				return this.FormMSG(123, 'HR templates');
			}
			return this.FormMSG(23, 'Memo deal templates');
		},
		getTitle() {
			if (store.isPme()) {
				return this.FormMSG(120, 'Legal entity information');
			}
			return this.FormMSG(20, 'Project information');
		},
		projectData() {
			return store.state.myProject;
		},
		profileData() {
			return store.state.myProfile;
		}
	},
	mounted() {
		// Check if there's an argument to open subscription tabs
		if (this.$route.query && this.$route.query.activeTabs === 'subscriptions') {
			this.$nextTick(() => {
				this.tabIndex = 4;
			});
		}
	},
	methods: {
		handleClickManageSubscriptionAndroid() {
			const action = async () => {
				const resultAddLicenseRequest = await addLicenseRequestForAndroidSubscription({
					email: store.state.myProfile.email,
					phone: store.state.myProfile.phone,
					projectTitle: store.state.myProject.titre,
					userId: parseInt(store.state.myProfile.id),
					requestType: 4,
					userName: store.state.myProfile.name,
					userFirstName: store.state.myProfile.firstName,
					language: store.appLanguage()
				});

				const url = `${process.env.BASE_URL}/#/pages/login?MS=${resultAddLicenseRequest.xid}`;
				window.open(url, '', 'width=300, height=500');
			};

			this.confirmModal(action, this.FormMSG(49, 'You will be redirected to an external page to follow your subscription management. Want to continue?'));
		},
		handleClickHere() {
			const url = 'http://www.thegreenshot.green/';
			var temp = document.createElement('textarea');
			var tempMsg = document.createTextNode(url);
			temp.appendChild(tempMsg);

			document.body.appendChild(temp);
			temp.select();
			document.execCommand('copy');
			document.body.removeChild(temp);

			this.createToastForMobile(this.FormMSG(50, 'Copy successfully'));
		},
		refreshData() {
			this.showPayCode = store.state.myProject.payrollInterface === 0 ? false : true;
		}
	}
};
</script>

<style lang="scss" scoped>
.tab-project {
	background-color: #ffffff;
	border-radius: 2px;
	// padding: 3px 4px 3px 4px;
}
</style>
