var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c("div", { staticClass: "animated fadeIn" }, [
      _c(
        "div",
        { staticClass: "p-3 no-border" },
        [
          _c(
            "b-row",
            {
              staticClass: "no-border",
              staticStyle: { "flex-direction": "row" },
            },
            [
              _c("b-col", { attrs: { cols: 6 } }, [
                _c("p", { staticClass: "fw-400" }, [
                  _c(
                    "span",
                    { staticClass: "info" },
                    [_c("Info", { attrs: { size: 15 } }), _vm._v("  ")],
                    1
                  ),
                  _vm._v(
                    _vm._s(
                      _vm.FormMSG(
                        10,
                        "Premises are rented office spaces that are not related to filming locations."
                      )
                    ) + "\n\t\t\t\t\t"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right mb-2", attrs: { cols: 12 } },
            [
              _c(
                "b-button",
                {
                  staticClass: "disabled",
                  on: { click: _vm.handleCreateNewAndShowModal },
                },
                [
                  _c("Plus", { attrs: { size: 15 } }),
                  _vm._v(" " + _vm._s(_vm.FormMSG(32, "New Premises")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-4" },
            [
              _c("b-table", {
                staticClass: "fw-400",
                staticStyle: { "text-align": "left" },
                attrs: {
                  bordered: "",
                  "selected-variant": _vm.selectedColor,
                  small: "",
                  selectable: "",
                  "sticky-header": "800px",
                  "select-mode": _vm.selectMode,
                  "empty-text": _vm.FormMSG(98, "No premises found"),
                  responsive: "sm",
                  "head-variant": "dark",
                  striped: "",
                  items: _vm.premises,
                  fields: _vm.premFields,
                  "show-empty": "",
                },
                on: { "row-dblclicked": _vm.rowClicked },
                scopedSlots: _vm._u([
                  {
                    key: "head(createdAt)",
                    fn: function (data) {
                      return [
                        _c("div", { staticClass: "text-left pl-2" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(6, "Date")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "head(checked)",
                    fn: function (data) {
                      return [
                        _vm.premises.length > 0
                          ? _c(
                              "div",
                              { staticClass: "text-center pl-2" },
                              [
                                _c("b-form-checkbox", {
                                  attrs: { size: "lg" },
                                  on: { change: _vm.handleInputSelectAll },
                                  model: {
                                    value: _vm.selectAll,
                                    callback: function ($$v) {
                                      _vm.selectAll = $$v
                                    },
                                    expression: "selectAll",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(checked)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("b-form-checkbox", {
                              attrs: { size: "lg" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleInputItemChecked(
                                    $event,
                                    data.item
                                  )
                                },
                              },
                              model: {
                                value: data.item.checked,
                                callback: function ($$v) {
                                  _vm.$set(data.item, "checked", $$v)
                                },
                                expression: "data.item.checked",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(option)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "text-center cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.rowClicked(data.item)
                              },
                            },
                          },
                          [
                            _c("Edit", {
                              attrs: { color: "#00a09c", size: 20 },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.premises.length > 0
                ? _c(
                    "b-button",
                    {
                      staticClass: "w-10",
                      attrs: {
                        disabled: _vm.premiseId <= 0,
                        variant: "custom-outline-gray",
                      },
                      on: { click: _vm.multipleDelete },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(350, "Delete")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("premise-modal", {
            attrs: {
              isUpdate: _vm.isUpdate,
              showPremiseModal: _vm.showPremiseModal,
              premiseData: _vm.premise,
            },
            on: { close: _vm.hideModal, "premise:Update": _vm.handleUpdate },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }