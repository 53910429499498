var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "b-row",
        { staticClass: "px-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: 4 } },
            [
              _c("treeselect", {
                attrs: { options: _vm.equipmentOption },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.equipment.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "type", $$v)
                  },
                  expression: "equipment.type",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: 5 } },
            [
              _c("b-form-input", {
                attrs: { type: "text" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.equipment.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "description", $$v)
                  },
                  expression: "equipment.description",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: 2 } },
            [
              _c("b-form-input", {
                attrs: { type: "number" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.equipment.quantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.equipment, "quantity", $$v)
                  },
                  expression: "equipment.quantity",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticStyle: { "align-items": "center", display: "flex" } },
            [
              _c(
                "div",
                {
                  staticClass: "cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(_vm.equipment.id)
                    },
                  },
                },
                [_c("MinusCircle", { attrs: { color: "tomato", size: 20 } })],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }