<template>
	<b-row class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-col sm="4" md="4" lg="4" xl="4">
			<b-form-group :label="FormMSG(1, 'Name')">
				<b-form-input
					type="text"
					v-model="company.name"
					autocomplete="off"
					:class="{
						'is-invalid': $v.company.name.$error
					}"
					@keyup="handleInputChanged('name')"
				/>
			</b-form-group>
		</b-col>
		<b-col sm="8" md="8" lg="8" xl="8">
			<b-form-group :label="FormMSG(2, 'Address')">
				<v-address
					:params="{ title: FormMSG(3, 'Address') }"
					:address-id="company.addressId"
					:is-submitted="true"
					:error="$v.company.addressId.$error"
					@change="handleDynamicAddressChange"
					@delete="handleDynamicAddressDelete"
					@address-maplink-change="handleAddressMapLinkChange"
				/>
			</b-form-group>
		</b-col>
	</b-row>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import { greaterThanZero } from '@/shared/utils';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import _ from 'lodash';
import { INPUT_TYPE } from '@/shared/constants';

export default {
	name: 'FinanceCompanyForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		'v-address': MainAddressLocation
	},
	props: {
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		return {
			company: null
		};
	},
	methods: {
		handleDynamicAddressChange(payload) {
			this.company.addressId = +payload.id;

			this.$emit('finance-company-form:updated', {
				index: this.index,
				item: this.company,
				isLineValid: !this.$v.$invalid
			});
		},
		handleDynamicAddressDelete() {
			this.company.addressId = 0;
		},
		handleAddressMapLinkChange(payload) {
			// console.log({ mapAddress: payload });
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.company[fieldName] = +this.company[fieldName];
			} else if (type === INPUT_TYPE.FLOAT) {
				this.company[fieldName] = parseFloat(this.company[fieldName]);
			}

			this.$emit('finance-company-form:updated', {
				index: this.index,
				item: this.company,
				isLineValid: !this.$v.$invalid
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.company = _.cloneDeep(value);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			company: {
				name: {
					required,
					min: minLength(3)
				},
				addressId: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};
	}
};
</script>
<style lang="scss" scoped></style>
